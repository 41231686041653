import React, { useEffect, useState, useRef } from 'react';
import './Location.css';
import useGetUrlParams from 'utils/useGetUrlParams';
import Select from 'multiselect-react-dropdown';
 
const Location = ({ changeLocation, locationList, enableLocationFilter, clearField,   settings, pageID } ) => {
  let locationRef = useRef();
  const [selectedOption, setSelectedOption] = useState();
 
  let [locationPlaceholder, setLocationPlaceholder] = useState(settings?.Dictionaries['Search.SearchLocationTitle'] || 'Locations');
  let [Location] = useGetUrlParams('Location');
  
 
  
  useEffect(() => {
    if(clearField > 0){
      setSelectedOption([]);
      changeLocation('');
      setLocationPlaceholder("Locations");
    }
  }, [clearField]);

  useEffect(() => {
     
    if (pageID && locationList ) {
      const optionMatchingPageID = options.find(option => option.id === pageID);
      
      if (optionMatchingPageID) {
        
        setSelectedOption([{ name: optionMatchingPageID.name, id: optionMatchingPageID.id }]);
        setTimeout(() => {
          changeLocation(pageID.toString());  
        }, 1);  
      }
    }
  }, [Location, pageID, locationList]);  
 

  useEffect(() => {
      let urlParams = new URLSearchParams(window.location.search);
      let location = urlParams.get('location') || urlParams.get('Location');
 

      if (!Location && location){
        Location = location;
      }
      
 
      if (Location === ''){
        changeLocation('');
      }

    
      
    if (Location && locationList){
        for (let i = 0; i < Location.length; ++i) {
          changeLocation(location);
        }

         
        const selectedIds = Location.split(',').filter(Boolean).map(Number);
   
        const selectedOptions = options.filter(option => selectedIds.includes(option.id))
          .map(option => ({ id: option.id, name: option.name }));
        setSelectedOption(selectedOptions);
 
        const isEmpty = selectedOptions.length === 0;
        const length = selectedOptions.length;
        if (!isEmpty) {
          setLocationPlaceholder(`Locations (${length})`);
        } else {
          setLocationPlaceholder(`Locations`);
        }
    }

    if (Location === 'All'){
      setLocationPlaceholder("Locations");
    }

  }, [Location, locationList]);

 
  let options = null;
  if (locationList && locationList !== '') {
    options = locationList
      .filter(({ level }) => level === 3)
      .map(({ Id, Name }) => ({
        name: Name,
        id: Id,
        selected: true
      }));
  }
 

 
 
  function changeLocaationParameter(e){
    
    if(e){
      if (e.length === 0) setLocationPlaceholder(`Locations`);
      if(e.length > 0){
        setLocationPlaceholder(`Locations (${e.length})`);
      }
      const ids = e.map(obj => obj.id).join(',');
      changeLocation(ids);
  
    }
  }

 
 
  return (
    <>
       
      {(enableLocationFilter && options) && 
        <>
        <Select 
          ref={locationRef}
          options={options}  
          showCheckbox={true}
          onSelect={changeLocaationParameter}  
          onRemove={changeLocaationParameter}
          placeholder={locationPlaceholder}
          hidePlaceholder={false}
          hideSelectedList={true}
          displayValue="name"  
          selectedValues={selectedOption}
          style={{
        
            multiselectContainer: {
              color: 'black',
              border: '1px solid #ccc',
              borderLeft: '0px solid #ccc',
              borderRight: '1px solid #ccc',
              zIndex: '9999',
            },
            searchBox: {
           
              borderRadius: '0px',
              'height': '48px'
            }
          }}
         >
          
        </Select >
        </>
      }
    </>
    
  );
};

Location.propTypes = {};

Location.defaultProps = {};

export default Location;
