import React, { useEffect, useState, useContext } from 'react';
import { Link, Element, Events } from 'react-scroll';
import { SEOContext, CurrencyContext } from '../../contexts/SiteSettingsProvider';
import useWindowSize from "utils/useWindowSize";
import "tailwindcss/tailwind.css";
import CalendarDataContext from '../../contexts/CalendarDataContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faList, faCalendar, faMapMarkerAlt, faStar, faDollarSign } from '@fortawesome/free-solid-svg-icons';
// New imports
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import WishButton from 'components/SearchResult/WishButton/WishButton';

// New imports
import ImageSlider from '../../components/PropertyPage/ImageSlider/ImageSlider';

import BookingForm from '../../components/PropertyPage/BookingForm/BookingForm';
import BookingFormMobile from '../../components/PropertyPage/BookingFormMobile/BookingFormMobile';

import Description from '../../components/PropertyPage/Description/Description';
import Amenities from '../../components/PropertyPage/Amenities/Amenities';
import Rates from '../../components/PropertyPage/Rates/Rates';
import AvailabilityCalendar from '../../components/PropertyPage/AvailabilityCalendar/AvailabilityCalendar';
import BreadCrumbs from '../../components/PropertyPage/BreadCrumbs/BreadCrumbs';
import EnquiryForm from '../../components/PropertyPage/EnquiryForm/EnquiryForm';
import Map from '../../components/PropertyPage/Map/Map';
import Reviews from '../../components/PropertyPage/Reviews/Reviews';
import MainAttrs from '../../components/PropertyPage/MainAttrs/MainAttrs';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const Property = ({ pageId, keyID, GoogleMapAPIKey }) => {
    const { updateSeo } = useContext(SEOContext);

    const [isLoading, setIsLoading] = useState(true);
    const windowSize = useWindowSize();
    const [currency, setCurrency] = useContext(CurrencyContext);
    const [calendarData, setCalendarData] = useState([]);
    const [isMobile, setIsMobile] = useState(false);

    const [selectedDates, setSelectedDates] = useState({ startDate: null, endDate: null });
    const [images, setImages] = useState([]);
    const [reviews, setReviews] = useState([]);

    const [pageH1Title, setPageH1Title] = useState('');
    const [intro, setIntro] = useState('');
    const [description, setDescription] = useState('');
    const [propertyRates, setPropertyRates] = useState([]);
    const [geoLocation, setGeoLocation] = useState([]);
    const [distances, setDistances] = useState([]);
    const [locationDescription, setLocationDescription] = useState('');
    const [propertyAttributes, setPropertyAttributes] = useState({});
    const [floorPlan, setFloorPlan] = useState('');
    

    const [cons, setCons] = useState('');
    const [pros, setPros] = useState('');

    const [maxOccupancy, setMaxOccupancy] = useState(1);
    const [maxPets, setMaxPets] = useState(1);
    const [currencySymbol, setCurrencySymbol] = useState("£");
    const [hideCalendar, setHideCalendar] = useState(false);
    const [disableAvailability, setDisableAvailability] = useState(false);
    const [hideRates, setHideRates] = useState(false);
    const [plotSize, setPlotSize] = useState(null);
    const [propertyLicence, setPropertyLicence] = useState('');
    const [propertyReference, setPropertyReference] = useState('');
    const [regionDescription, setRegionDescription] = useState('');
    const [propertyRentalNotes, setPropertyRentalNotes] = useState('');
    const [virtualTour, setVirtualTour] = useState('');
    const [virtualTourTitle, setVirtualTourTitle] = useState('');
    const [breadcrumbs, setBreadcrumbs] = useState(null);
    const [propertyName , setPropertyName] = useState('');
    const [hideOnlineBooking, setHideOnlineBooking] = useState(null);
 

    const [showEnquire, setShowEnquire] = useState(false);
    const handleCloseEnquire = () => setShowEnquire(false);
    const handleShowEnquire = () => setShowEnquire(true);

    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);
    const [bookingCurrency, setBookingCurrency] = useState('GBP');
 
    const icons = {
        Description: faHome,
        Amenities: faList,
        Rates: currencySymbol,
        Availability: faCalendar,
        Map: faMapMarkerAlt,
        Reviews: faStar,
    };

    const MenuItem = ({ name, to }) => {
        return (
            <Link
                activeclassName="tw-border-b-2  tw-border-primary"
                to={to}
                spy={true}
                smooth={true}
                duration={300}
                className="tw-cursor-pointer tw-px-4 tw-font-bold tw-no-underline tw-py-2 tw-flex tw-items-center"
            >
                <FontAwesomeIcon icon={icons[name]} className="tw-mr-2 " />
                {icons[name] === currencySymbol && <span className="tw-mr-2">{currencySymbol}</span>}
                <span className="xl:tw-inline tw-hidden">{name}</span>
            </Link>
        );
    };


    useEffect(() => {
        if (windowSize.width > 800) {
            setIsMobile(false);
        }

        if (windowSize.width < 800) {
            setIsMobile(true);
        }
    }, [windowSize.width]);

 
    
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL}apis/propertypage?pageid=${pageId}&Currency=${currency}`) //${currency}`)
            .then(response => response.json())
            .then(data => {
                
                setIntro(data.Property.Details.Intro);
                setCons(data.Property.Details.Cons);
                setPros(data.Property.Details.Pros);
                setPageH1Title(data.Property.Details.PageH1Title);
                setReviews(data.Property.Details.Reviews);
                setDescription(data.Property.Details.MainDescription);
                setImages(data.Property.Details.SliderImages);
                setCalendarData(data.Property.Details.Calendar);
                setFloorPlan(data.Property.Details.FloorPlan);

                setPropertyRates(data.Property.Details.PropertyRates);
                setPropertyAttributes(data.Property.Details.PropertyAttributes);
                setGeoLocation(data.Property.Details.GeoLocation);
                setDistances(data.Property.Details.Distances);
                setLocationDescription(data.Property.Details.LocationDescription);
                 
                setIsLoading(false);  // Data has been fetched successfully

                setMaxOccupancy(data.Property.Details.MaxOccupancy);
                setMaxPets(data.Property.Details.MaxPets);
                setCurrencySymbol(data.Property.Currency);
                setHideCalendar(data.Property.Details.HideCalendar);
                setDisableAvailability(data.Property.Details.DisableAvailability);
                setHideRates(data.Property.Details.HideRates);
                setPlotSize(data.Property.Details.PlotSize);
                setPropertyLicence(data.Property.Details.PropertyLicence);
                setPropertyReference(data.Property.Details.PropertyReference);
                setRegionDescription(data.Property.Details.RegionDescription);
                setPropertyRentalNotes(data.Property.Details.PropertyRentalNotes);
                setVirtualTour(data.Property.Details.VirtualTour);
                setVirtualTourTitle(data.Property.Details.VirtualTourTitle);
                setBreadcrumbs(data.Settings.BreadCrumbs);
                setPropertyName(data.Property.Details.PropertyName);
                setHideOnlineBooking(data.Property.Details.HideOnlineBooking);
                updateSeo(data.Settings.Seo);

                setMinPrice(data.Property.Details.PropertyMinRate);
                setMaxPrice(data.Property.Details.PropertyMaxRate);
                setBookingCurrency(data.Property.Details.CurrencySymbol);
            })
            .catch(error => {
                console.error('Error fetching blocked dates:', error);
                setIsLoading(false);  // Data has been fetched successfully
            });
    }, [currency]);


 

    useEffect(() => {
        Events.scrollEvent.register('begin', (to, element) => {
            console.log('begin', to, element);
        });

        Events.scrollEvent.register('end', (to, element) => {
            console.log('end', to, element);
        });

        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);


    const handleDateChange = (startDate, endDate) => {
        setSelectedDates({ startDate, endDate });
    };

    return (
        <>
 
            <div className="tw-w-full tw-overflow-hidden">
                {/* Image slider */}
                <ImageSlider images={images} isLoading={isLoading} />
            </div>

            <div className="tw-container tw-mx-auto tw-py-2">

                <div className={`tw-w-full tw-grid tw-grid-cols-12 tw-gap-4 tw-mt-2 ${windowSize.width < 1024 ? 'tw-grid-flow-row-dense' : ''}`}>
                    {/* Sidebar */}
                    <div className={`tw-z-50 tw-col-span-12  ${windowSize.width >= 1024 ? 'tw-z-10 tw-relative md:tw-col-span-4 md:tw-col-start-9 tw-row-start-1 xl:tw-ml-5' : 'tw-order-first'}`}>
                        <div className="tw-sticky tw-top-0 tw-z-50 tw-white ">

                            {!isMobile &&
                                <>
                                    <div className="tw-white tw-shadow-xl tw-mt-3 tw-pb-3">
                                        <CalendarDataContext.Provider value={calendarData} isLoading={isLoading}>
                                            {<BookingForm
                                                selectedDates={selectedDates}
                                                onDateChange={handleDateChange}
                                                maxOccupancy={maxOccupancy}
                                                maxPets={maxPets}
                                                currencySymbol={currency}
                                                pageId={pageId}
                                                disableAvailability={disableAvailability}
                                                hideOnlineBooking={hideOnlineBooking}
                                            />}
                                        </CalendarDataContext.Provider>
                                        <span 
                                            onClick={handleShowEnquire}
                                             className="hover:tw-text-[#272727] tw-cursor-pointer tw-m-5 tw-uppercase tw-border tw-border-black tw-text-black tw-no-underline tw-py-2 tw-px-4 tw-rounded tw-block tw-text-center tw-mt-3 tw-transition tw-duration-200"
                                        >
                                            Make An Enquiry
                                        </span>
                                    </div>
                                </>
                            }

                            <Modal show={showEnquire} onHide={handleCloseEnquire}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Enquire</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <EnquiryForm
                                            pageId={pageId}
                                            currencySymbol={currency}
                                        />
                                    </Modal.Body>
                            </Modal>

                            {/* {!hideOnlineBooking && isMobile &&
                                <div className='tw-fixed tw-z-1000 tw-bottom-0 tw-bg-white tw-w-full tw-shadow-lg'>
                                    <span
                                        onClick={handleShowEnquire}
                                        className="hover:tw-text-[#272727] tw-cursor-pointer tw-m-5 tw-uppercase tw-border tw-border-black tw-text-black tw-no-underline tw-py-2 tw-px-4 tw-rounded tw-block tw-text-center tw-mt-3 tw-transition tw-duration-200"
                                    >
                                        Make An Enquiry
                                    </span>
                                </div>
                            } */}

                            {(isMobile ) &&
                                <CalendarDataContext.Provider value={calendarData} isLoading={isLoading}>
                                    {<BookingFormMobile
                                        selectedDates={selectedDates}
                                        onDateChange={handleDateChange}
                                        maxOccupancy={maxOccupancy}
                                        maxPets={maxPets}
                                        currencySymbol={currencySymbol}
                                        pageId={pageId}
                                        disableAvailability={disableAvailability}
                                        hideOnlineBooking={hideOnlineBooking}
                                        setShowEnquire={() => setShowEnquire(true)} 
                                        minPrice={minPrice}
                                        maxPrice={maxPrice}
                                        bookingCurrency={bookingCurrency}
                                    />}
                                </CalendarDataContext.Provider>
                            }  
 
                        </div>
                    </div>
                    {/* Main content */}
                    <div className={`tw-col-span-12 ${windowSize.width >= 1024 ? 'tw-z-10 tw-relative md:tw-col-span-8 md:tw-col-start-1 md:tw-mr-5' : 'tw-order-last'}`}>
                        <div className="  ">
                            {/* Sections */}
                           
                            <Element name="description" className="property-section" >

                                <WishButton showText={true} size="2xl" heartColor="#f23b79" className='xl:tw-absolute tw-z-1 tw-right-5 tw-top-10 tw-cursor-pointer' id={pageId} />
                                <h1 className="tw-text-xl tw-mb-0 tw-pr-0lg:tw-pr-10">{pageH1Title}</h1>  
                                {propertyName && <h2 className="tw-text-3xl tw-mb-5 tw-mt-1">{propertyName}{propertyReference && <span className="propertyReference "> - Ref: {propertyReference}</span>}</h2>}

                                {(breadcrumbs && breadcrumbs.length > 0) && <BreadCrumbs data={breadcrumbs} />}

                                <MainAttrs propertyAttributes={propertyAttributes} />  

                                <div className=" tw-sticky tw-top-0 tw-bg-white tw-w-full tw-flex tw-justify-between">
                                    <MenuItem name="Description" to="description" />
                                    <MenuItem name="Amenities" to="amenities" />
                                    {!hideRates && <MenuItem name="Rates" to="rates" />}
                                    {!hideCalendar && <MenuItem name="Availability" to="availability" />}
                                    <MenuItem name="Map" to="map" />
                                    <MenuItem name="Reviews" to="reviews" />
                                </div>

                                <Description intro={intro} description={description} cons={cons} pros={pros} isLoading={isLoading} />

                            
                                {virtualTour && 
                                   <>
                                        { virtualTourTitle }
                                        <div className="virtualTour" dangerouslySetInnerHTML={{ __html: virtualTour }} />
                                   </>
                                }
                                {floorPlan && <div className="floorPlan" dangerouslySetInnerHTML={{ __html: floorPlan }} />}
                                {plotSize && <div className="plotSize" dangerouslySetInnerHTML={{ __html: plotSize }} />} 
                                {propertyLicence && 
                                <>
                                    <b>Property Licence: </b>
                                    <span className="propertyLicence tw-ml-2" dangerouslySetInnerHTML={{ __html: propertyLicence }} />
                                </>} 
                               
                            </Element>

                            <Element name="amenities" className="property-section">
                                <Amenities propertyAttributes={propertyAttributes} isLoading={isLoading} />
                            </Element>

                            {!hideRates &&
                                <Element name="rates" className="property-section">
                                    <Rates rates={propertyRates} isLoading={isLoading} />
                                    <div className="table-responsive">
                                        {propertyRentalNotes && <div className="propertyRentalNotes" dangerouslySetInnerHTML={{ __html: propertyRentalNotes }} />}

                                    </div>
                                </Element>
                                
                            }

                            {!hideCalendar && 
                                <Element name="availability" className="property-section">
                                    <CalendarDataContext.Provider value={calendarData}>
                                        <AvailabilityCalendar selectedDates={selectedDates} onDateChange={handleDateChange} isLoading={isLoading} disableAvailability={disableAvailability} />
                                    </CalendarDataContext.Provider>

                                    {/* <CalendarDataContext.Provider value={calendarData}>
                                    <AvailabilityCalendarFlatpickr selectedDates={selectedDates} onDateChange={handleDateChange} isLoading={isLoading} />
                                </CalendarDataContext.Provider>  */}


                                </Element>
                           }
                            <Element name="map" className="property-section">
                                <Map geoLocation={geoLocation} GoogleMapAPIKey={GoogleMapAPIKey} isLoading={isLoading} distances={distances} locationDescription={regionDescription} />
                            </Element>
                            <Element name="reviews" className="property-section">
                                <Reviews reviews={reviews} isLoading={isLoading} />
                            </Element>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Property;