import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './SearchSorter.module.css';
import Form from 'react-bootstrap/Form';
import { SortContext } from 'contexts/SiteSettingsProvider';

const SearchSorter = ({ updateSorting }) => {
  const { sort, order } = useContext(SortContext);

  useEffect(() => {
    const combinedValue = `${sort}:${order}`;
    const selectElement = document.getElementById('sortSelect');
    if (selectElement && combinedValue) {
      
      selectElement.value = combinedValue; // Directly set the combined value
    }
  }, [sort, order]);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const [newSort, newOrder] = value.split(':');
    updateSorting(newSort, newOrder);
  };

  return (
    <div className={styles.SearchSorter}>
      <Form.Select id="sortSelect" onChange={handleSelectChange}>
        <option value="">Please Select</option>
        <option value="propertymaxrate:asc">Price low to high</option>
        <option value="propertymaxrate:desc">Price high to low</option>
        <option value="sleeps:asc">Sleeps: ascending</option>
        <option value="sleeps:desc">Sleeps: descending</option>
        <option value="bedrooms:asc">Bedrooms: ascending</option>
        <option value="bedrooms:desc">Bedrooms: descending</option>
        <option value="nodeName:asc">Property name</option>
        <option value="propertyReference:asc">Property reference</option>
        <option value="ourpick:asc">Our Pick</option>
      </Form.Select>
    </div>
  );
};

SearchSorter.propTypes = {
  updateSorting: PropTypes.func.isRequired,
};

export default SearchSorter;
