import React, { useEffect, useState, useContext } from 'react';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/initialize';
import './BookingForm.scss';
import useWindowSize from "utils/useWindowSize";
import moment from 'moment-timezone';
import CalendarDataContext from '../../../contexts/CalendarDataContext';
import axios from 'axios';
import { extendMoment } from 'moment-range';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faCalendarDays } from '@fortawesome/pro-regular-svg-icons';
import _ from 'lodash';
const momentRange = extendMoment(moment);
 
 

const BookingForm = ({ selectedDates, onDateChange, maxOccupancy, maxPets, currencySymbol, pageId, disableAvailability, propertyMaxRate, propertyMinRate, hideOnlineBooking }) => {
    const CalendarData = useContext(CalendarDataContext);
    const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
    const [focusedInput, setFocusedInput] = useState(null);
    const { startDate, endDate } = selectedDates;
    const [adults, setAdults] = useState(1);
    const [children, setChildren] = useState(0);
    const [infants, setInfants] = useState(0);
    const [pets, setPets] = useState(0);
    const [bookingData, setBookingData] = useState(null);
    const windowSize = useWindowSize();
    const [calendarCols, setCalendarCols] = useState(2);
    const [error, setError] = useState(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const [orientation, setOrientation] = useState('horizonatal');
    const [withFullScreenPortal, setWithFullScreenPortal] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [reset, setReset] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(moment());
    const [isPrevMonthDisabled, setIsPrevMonthDisabled] = useState(true);
    const [daySize, setDaySize] = useState(40);

    useEffect(() => {
        const updateDaySize = () => {
            const container = document.getElementById('date-picker-container-booking-form');
            if (container) {
                const newDaySize = container.offsetWidth / 7; 
                setDaySize(newDaySize);
            }
        };

        // Initial calculation
        updateDaySize();

        // Add event listener for window resize
        window.addEventListener('resize', updateDaySize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateDaySize);
        };
    }, []);

    useEffect(() => {
        if (isMobile) {
            // document.body.style.overflow = isOpen ? 'hidden' : 'visible';
            // document.getElementsByTagName('html')[0].style.overflow = isOpen ? 'hidden' : 'visible';
        }
        if (isOpen) {
            // window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [isOpen]);

    useEffect(() => {
        if (currentMonth){
            checkIfPrevMonthDisabled(currentMonth);

        }
    }, [currentMonth]);

    useEffect(() => {
        const currentUrl = new URL(window.location.href);
        const searchParams = new URLSearchParams(currentUrl.search);

        const adults = searchParams.get("Adults");
        const children = searchParams.get("Children");
        const infants = searchParams.get("Infants");
        const checkIn = searchParams.get("CheckIn");
        const checkOut = searchParams.get("CheckOut");

        setAdults(adults || 2);
        setChildren(children || 0);
        setInfants(infants || 0);

        if (checkIn && checkOut) {
            console.log(checkIn, checkOut);
            onDateChange(moment.utc(checkIn, "DD/MM/YYYY"), moment.utc(checkOut, "DD/MM/YYYY"));
            setCurrentMonth(moment.utc(checkIn, "DD/MM/YYYY"));
        }
        setTimeout(() => {
            setFocusedInput('startDate');
        }, 1000);
    }, []);

    useEffect(() => {
        if (windowSize.width > 800) {
            setCalendarCols(2);
            setOrientation('horizontal');
            setWithFullScreenPortal(false);
            setIsMobile(false);
        }

        if (windowSize.width < 800) {
            setCalendarCols(1);
            setOrientation('vertical');
            //setOrientation('verticalScrollable');
            setWithFullScreenPortal(true);
            setIsMobile(true);
        }
    }, [windowSize.width]);

    useEffect(() => {
        const fetchBookingData = async () => {
            if (startDate === null || endDate === null) {
                return;
            }

            const formatDate = (date) => {
                if (typeof date === 'string' && /^\d{2}\/\d{2}\/\d{4}$/.test(date)) {
                    return date;
                }
                const day = date.date();
                const month = date.month() + 1; // Moment months are 0-indexed
                const year = date.year();
                return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
            };

            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}ipro/webmethod.ashx`, {
                    params: {
                        methodName: 'ipro.acc.booking.calc',
                        checkin: formatDate(startDate),
                        checkout: formatDate(endDate),
                        adults,
                        children,
                        infants,
                        pets,
                        propertyid: pageId,
                        propertylanguageId: 0,
                        sourceId: '',
                        currency: currencySymbol
                    }
                });

                if (response.data.Error) {
                    setError(response.data.Error);
                } else {
                    setBookingData(response.data);
                    setError(null);
                }
            } catch (error) {
                console.error("Failed to fetch booking data:", error);
            }
        };

        fetchBookingData();
    }, [startDate, endDate, adults, children, infants, pets, currencySymbol])

    const calendarDataMap = new Map(CalendarData.map(item => {
        const dateKey = item.t.split(" ")[0];
        return [dateKey, item];
    }));

    const isBlocked = day => {
        const dayInUTC = day.clone(); // Clone the day instead of converting to UTC

        const startDateInUTC = startDate ? startDate.clone() : null; // Clone the startDate if it exists
        const dateString = dayInUTC.format('DD/MM/YYYY');
        const currentDateObject = calendarDataMap.get(dateString);

        // Block past dates if disableAvailability is true
        if (disableAvailability) {
            if (day < new Date()) {
                return true;
            }
            return false;
        }

        // Block unavailable dates
        if (currentDateObject && !currentDateObject.e) {
            return true;
        }

        // Block dates before the selected start date
        if (startDateInUTC && dayInUTC.isBefore(startDateInUTC, 'day') && !endDate) {
            return true;
        }

        // Allow dates within the valid range when a start date is selected
        if (startDateInUTC && !endDate) {
            const minStayNumber = currentDateObject?.ms ? parseInt(currentDateObject.ms.replace('+', ''), 10) : 1;
            const potentialEndDate = startDateInUTC.clone().add(minStayNumber - 1, 'days');

            if (dayInUTC.isSameOrAfter(startDateInUTC, 'day') && dayInUTC.isSameOrBefore(potentialEndDate, 'day')) {
                return false; // Allow dates within the valid min stay range
            }
        }

        // Allow dates if they satisfy special booking conditions
        if (currentDateObject) {
            if (currentDateObject.c || currentDateObject.cs || currentDateObject.ce || currentDateObject.sb || currentDateObject.sbs || currentDateObject.sbe) {
                return false;
            }
        }

        // Block dates if no special condition is met and they are unavailable
        if (!currentDateObject || !currentDateObject.e) {
            return true;
        }

        // Allow dates that are changeover or special booking days
       // return !(currentDateObject.cs || currentDateObject.sbs);
    };


    const hasBookedDateInRange = _.memoize((startDate, endDates) => {
        if (!startDate || !endDates) {
            return false;
        }

        if (!Array.isArray(endDates)) {
            endDates = [endDates];
        }

        const endDate = endDates[0];
        const range = startDate.isBefore(endDate, 'day') ? momentRange.range(startDate, endDate) : momentRange.range(endDate, startDate);
        return Array.from(range.by('days')).some(dateInRange => {
            const dateInRangeString = dateInRange.format('DD/MM/YYYY');
            const dateInRangeObject = CalendarData.find(({ t }) => t.startsWith(dateInRangeString));
            return dateInRangeObject ? !dateInRangeObject.e : false;
        });
    });

    const isWithinMinStay = (minStay, nightDifference) => {
        if (!minStay) {
            console.warn('Invalid minStay value: ', minStay);
            return false;
        }

        if (typeof minStay === 'string') {
            if (minStay.includes('+')) {
                const minStayNumber = parseInt(minStay.replace('+', ''), 10);
                return nightDifference + 1 >= minStayNumber;
            } else {
                const availableStays = minStay.split(',').map(Number);
                return availableStays.includes(nightDifference + 1);
            }
        }

        else if (typeof minStay === 'number') {
            return nightDifference + 1 === minStay;
        }
        console.error('Invalid minStay value: ', minStay);
        return true;
    };

    const addMinStayToStartDate = (startDate, minStay) => {
        const date = moment(startDate);
        if (!minStay) {
            console.warn('Invalid minStay value: ', minStay);
            return null;
        }
        if (typeof minStay === 'string') {
            if (minStay.includes('+')) {
                const minStayNumber = parseInt(minStay.replace('+', ''), 10);
                return date.clone().add(minStayNumber, 'days');
            } else if (minStay.includes(',')) {
                const minStayNumbers = minStay.split(',').map(Number);
                return minStayNumbers.map(num => date.clone().add(num, 'days'));
            }
        } else if (typeof minStay === 'number') {
            return date.clone().add(minStay, 'days');
        }
        console.error('Invalid minStay value: ', minStay);
        return null;
    };

    const getMinStayForDate = (date) => {
        const dateString = date ? date.format('DD/MM/YYYY') : null;
        const dateObject = dateString ? CalendarData.find(({ t }) => t.startsWith(dateString)) : null;
        return dateObject && dateObject.ms ? dateObject.ms : '1+';
    };

    const hasSelectedBookedDateInRange = (day) => {
        let date = startDate || day;
        let minStay = getMinStayForDate(date);
        let potentialEndDate = addMinStayToStartDate(date, minStay);
        const satisfiesCondition = !hasBookedDateInRange(date, potentialEndDate);
        return !satisfiesCondition;
    }

    const renderDayContents = day => {
        if (disableAvailability) {
            return (
                <>
                    {day.format('D')}
                </>
            );
        }

        let classNames = '';
        const dateString = day.format('DD/MM/YYYY');
        const currentDateObject = CalendarData.find(({ t }) => t.startsWith(dateString));
        let tooltipText = '';
        const selectedBookedDateInRange = hasSelectedBookedDateInRange();

        const isDateWithinRange = (date, start, end) => date.isSameOrAfter(start) && date.isSameOrBefore(end);

        const findBookedRanges = () => {
            return CalendarData
                .filter(({ e }) => !e) // Only consider booked dates
                .map(({ t }) => moment(t, 'DD/MM/YYYY'));
        };

        const checkValidEndDate = (startDate, endDate, minStay) => {
            if (!startDate || !endDate || !minStay) {
                return false;
            }

            // Calculate the minimum valid end date
            const minValidEndDate = startDate.clone().add(minStay, 'days');
            const bookedRanges = findBookedRanges();

            // Check if the end date is before the minimum valid end date
            if (endDate.isBefore(minValidEndDate)) {
                return true; // This date would show the min stay message
            }

            // Check if the end date overlaps with any booked ranges
            for (const bookedDate of bookedRanges) {
                if (isDateWithinRange(bookedDate, startDate, endDate)) {
                    return true; // This date would show the min stay message
                }
            }

            return false; // No overlap, valid date
        };

        const checkPotentialStartDate = (day, minStayNumber) => {
            const bookedRanges = findBookedRanges();
            const potentialEndDate = day.clone().add(minStayNumber, 'days');

            for (const bookedDate of bookedRanges) {
                if (isDateWithinRange(bookedDate, day, potentialEndDate)) {
                    return true; // This day overlaps with a booked date considering the min stay
                }
            }

            return false; // Valid start date
        };

        if (currentDateObject) {
            const minStayNumber = currentDateObject?.ms ? parseInt(currentDateObject.ms.replace('+', ''), 10) : null;

            if (selectedBookedDateInRange) {
                classNames += ' no-hover';
            }

            // If no dates are selected, and the potential start day overlaps with a booked range considering min stay, show a message but don't block
            if (!startDate && minStayNumber && checkPotentialStartDate(day, minStayNumber)) {
                tooltipText = `${minStayNumber} nights minimum`;
                setShowTooltip(true);
            }

            if (startDate && endDate) {
                // Reset all days when both start and end dates are selected
                classNames = '';
                tooltipText = '';
            } else if (startDate && !endDate) {
                // Block all previous days when start day is selected
                if (day.isBefore(startDate, 'day')) {
                    classNames += ' tw-cursor-not-allowed tw-text-gray-400';
                    tooltipText = 'Select a date after the start date';
                } else if (minStayNumber) {
                    const isValidEndDate = checkValidEndDate(startDate, day, minStayNumber);
                    console.log('isValidEndDate:', isValidEndDate, startDate, day, minStayNumber);

                    // Show the tooltip only if this is not the valid end date
                    if (isValidEndDate && (!currentDateObject.e || !currentDateObject.cs)) {
                        tooltipText = `${minStayNumber} nights minimum`;
                        setShowTooltip(true);
                    }
                }

                // Block days after the first booking period when start date is selected
                const bookedRanges = findBookedRanges();
                const firstBookedDateAfterStart = bookedRanges.find(bookedDate => bookedDate.isAfter(startDate));

                if (firstBookedDateAfterStart && day.isAfter(firstBookedDateAfterStart, 'day')) {
                    classNames += ' tw-cursor-not-allowed tw-text-gray-400';
                    tooltipText = 'Select a date before the next booked range';
                }
            }

            if (!currentDateObject.e) {
                tooltipText = 'Booked';
                classNames += ' booked tw-bg-gray-300 tw-text-white tw-cursor-not-allowed';
            }

            // Ensure that the start day and valid subsequent days are selectable
            if (startDate && minStayNumber && !endDate) {
                const potentialEndDate = startDate.clone().add(minStayNumber, 'days');

                if (day.isSameOrAfter(startDate, 'day') && day.isSameOrBefore(potentialEndDate, 'day')) {
                    classNames = ''; // Remove blocking classes
                    const isValidEndDate = checkValidEndDate(startDate, day, minStayNumber);
                    // Show tooltip for min stay only if the day is not a valid end day (available date)
                    if (!isValidEndDate && !currentDateObject.e || !currentDateObject.cs) {
                        //tooltipText = `${minStayNumber} nights minimum 2`;
                    } else {
                        tooltipText = ''; // Hide tooltip for valid end date
                    }
                }
            }
        }

        return (
            <div className={`${classNames} tw-relative tw-flex tw-h-full tw-justify-center tw-items-center`}>
                {day.format('D')}
                {(showTooltip && tooltipText && tooltipText.length > 0) &&
                    <div className='calendar_tooltipText'
                        style={{
                            position: 'absolute',
                            left: `${cursorPos.x - 0}px`,
                            top: `${cursorPos.y - 30}px`,
                            zIndex: 1000,
                        }}
                    >
                        {tooltipText}
                    </div>
                }
            </div>
        );
    };

    const handleDatesChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
        if (!newStartDate && !newEndDate && !startDate && !endDate) {
            console.log(focusedInput);
        }

        if (startDate && endDate) {
            onDateChange(newStartDate, null);
            setFocusedInput('endDate');
            return;
        }

        if (disableAvailability) {
            onDateChange(newStartDate, newEndDate);
            setFocusedInput(focusedInput === 'startDate' ? 'endDate' : 'startDate');
            return;
        }

        let selectedBookedDateInRange = hasSelectedBookedDateInRange(newStartDate || newEndDate);
        if (selectedBookedDateInRange) {
            onDateChange(null, null);
            setFocusedInput('startDate');
            return;
        }

        if (startDate && newStartDate && endDate && newEndDate) {
            if (newStartDate.isAfter(startDate) && newStartDate.isBefore(endDate)) {
                onDateChange(newStartDate, null);
                setFocusedInput('startDate');
                return;
            }
            if (newStartDate.isBefore(startDate)) {
                onDateChange(newStartDate, null);
                setFocusedInput('startDate');
                return;
            }
            if (newEndDate.isAfter(endDate)) {
                onDateChange(newEndDate, null);
                setFocusedInput('startDate');
                return;
            }
        }

        onDateChange(newStartDate, newEndDate);
        setFocusedInput(focusedInput === 'startDate' ? 'endDate' : 'startDate');
    };

    const handleClear = () => {
        onDateChange(null);
    };

    const handleClose = () => {
        setFocusedInput(false);
        setIsOpen(false);
    };

    const renderCalendarInfo = () => (
        <div className="tw-flex   tw-mb-2">
            <div className="tw-flex tw-items-center">
                <div className="tw-w-4 tw-h-4 tw-mr-2 tw-bg-primary"></div>
                <span>Selected</span>
            </div>
             
            <div className="tw-flex tw-items-center tw-ml-4">
                <div className="tw-w-4 tw-h-4 tw-mr-2 tw-bg-gray-300"></div>
                <span>Booked</span>
            </div>
        </div>
    );

    const handleMonthChange = (newMonth) => {
        console.log('Month changed:', newMonth.format('YYYY-MM'));
        setCurrentMonth(newMonth);
        checkIfPrevMonthDisabled(newMonth);
    };

    const checkIfPrevMonthDisabled = (month) => {
        console.log('checkIfPrevMonthDisabled triggered:', month);
        const isDisabled = month.isSame(moment(), 'month');
        console.log('checkIfPrevMonthDisabled triggered:', isDisabled);
        setIsPrevMonthDisabled(isDisabled);
    };

    const getDuration = () => {
        if (startDate && endDate) {
            const duration = endDate.diff(startDate, 'days');
            const formattedStartDate = startDate.format('Do  MMM YYYY');
            const formattedEndDate = endDate.format('Do  MMM YYYY');
            return (
                <div className='tw-flex '>
                    <FontAwesomeIcon 
                        icon={faCalendarDays} 
                        className="tw-mr-2 tw-mt-1" 
                        style={{
                            color: '#a3a3a3',
                            fontSize: '1.5rem'
                        }}
                    />
                    <div>
                        <b>{formattedStartDate} - {formattedEndDate}</b><br />
                        <span className="">{duration} {duration > 1 ? 'nights' : 'night'}</span>
                    </div>
                </div>
            )
        }
        return '';
    };

    const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
        const currentMonth = moment().month();
        const currentYear = moment().year();

        const monthOptions = moment.months().map((label, value) => {
            // Exclude past months if the current year is selected
            if (month.year() === currentYear && value < currentMonth) {
                return null;
            }
            return (
                <option key={value} value={value}>
                    {label}
                </option>
            );
        });

        const yearOptions = [];
        for (let i = currentYear; i <= currentYear + 10; i++) {
            yearOptions.push(
                <option key={i} value={i}>
                    {i}
                </option>
            );
        }

        const handleMonthSelect = (e) => {
            const selectedMonth = parseInt(e.target.value, 10);

            // Alert and do nothing if the selected month is in the past
            if (month.year() === currentYear && selectedMonth < currentMonth) {
                alert('You cannot select a past month.');
                return;
            }

            const newMonth = month.clone().month(selectedMonth);
            onMonthSelect(month, selectedMonth);
            handleMonthChange(newMonth);
        };

        const handleYearSelect = (e) => {
            const selectedYear = parseInt(e.target.value, 10);
            const newYear = month.clone().year(selectedYear);

            // If the selected year is the current year, make sure the month is not in the past
            if (selectedYear === currentYear && month.month() < currentMonth) {
                alert('You cannot select a past month.');
 
                return;
            }

            onYearSelect(month, selectedYear);
            handleMonthChange(newYear);
        };

        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '5px',
            }}>
                <select
                    value={month.month()}
                    onChange={handleMonthSelect}
                    className="tw-text-center"
                >
                    {monthOptions}
                </select>
                <select
                    value={month.year()}
                    onChange={handleYearSelect}
                >
                    {yearOptions}
                </select>
            </div>
        );
    };
    return (
        <div className="tw-px-5">
            <div className="date-picker-container date-picker-container-booking-form tw-m-auto" id='date-picker-container-booking-form'>
                    <DayPickerRangeController
                        startDate={startDate}
                        endDate={endDate}
                        onDatesChange={handleDatesChange}
                        focusedInput={focusedInput}
                        daySize={daySize}
                        numberOfMonths={1}
                        onNextMonthClick={() => handleMonthChange(currentMonth.clone().add(1, 'month'))}
                        onPrevMonthClick={() => {
                            if (!isPrevMonthDisabled) {
                                handleMonthChange(currentMonth.clone().subtract(1, 'month'));
                            }
                        }}
                        hideKeyboardShortcutsPanel={true}
                        noBorder={true}
                        renderDayContents={renderDayContents}
                        renderCalendarInfo={renderCalendarInfo}
                        renderMonthElement={renderMonthElement}
                        isOutsideRange={isBlocked}
                        transitionDuration={0}
                        firstDayOfWeek={1}
                        verticalBorderSpacing={1}
                        initialVisibleMonth={() => currentMonth}
                        navPrev={isPrevMonthDisabled ? <span style={{ visibility: 'hidden' }}>Prev</span> : null}
                    />
            </div>

            {startDate && endDate &&
                <div
                    onClick={(e) => {
                        handleClear();
                    }}
                    className="tw-hover:text-blue-800 tw-font-bold tw-cursor-pointer tw-border tw-text-center tw-p-2 tw-mb-3">
                    Clear Dates
                </div>
            }

            {getDuration() &&
                <div className="tw-mt-0 tw-mb-2 tw-p-3 tw-bg-slate-100 ">
                   {getDuration()} 
                </div>
            }

            <div className="tw-mt-0">
                {[{ label: 'Adults', count: adults, setCount: setAdults, min: 1, max: maxOccupancy - children },
                { label: 'Children', count: children, setCount: setChildren, min: 0, max: maxOccupancy - adults },
                { label: 'Infants', count: infants, setCount: setInfants, min: 0, max: 20 },
                { label: 'Pets', count: pets, setCount: setPets, min: 0, max: maxPets }].map(({ label, count, setCount, min, max }, index) => (
                    (label !== 'Pets' || maxPets > 0) && (
                        <div key={index} className="tw-flex tw-justify-between tw-items-center tw-my-1">
                            <h6 className="tw-w-1/4">{label}</h6>
                            <div className="tw-w-3/4 tw-flex tw-justify-end">
                                <button onClick={() => setCount(count > min ? count - 1 : min)} className="tw-flex tw-items-center tw-justify-center tw-border tw-rounded-full tw-w-[30px] tw-h-[30px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="tw-w-4 tw-h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 12h12" />
                                    </svg>
                                </button>
                                <div className="tw-px-3 tw-py-0 tw-mx-2 tw-w-[30px] tw-text-center">{count}</div>
                                <button onClick={() => setCount(count < max ? count + 1 : max)} disabled={count >= max} className={`tw-flex tw-items-center tw-justify-center tw-border tw-rounded-full tw-w-[30px] tw-h-[30px] ${count >= max ? 'tw-opacity-50 tw-cursor-not-allowed' : ''}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="tw-w-4 tw-h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v12m-6-6h12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    )
                ))}
            </div>

            {(error && startDate && endDate) && <p className="tw-text-red-500">{error}</p>}

            {(!error && startDate && endDate) &&
                <div>

                    {/* {bookingData && bookingData.RenterAmount > 0 &&
                        <div className="tw-flex tw-justify-between">
                            <span>Rental Amount:</span>
                            <span>{bookingData.CurrencySymbol}{bookingData.RenterAmount}</span>
                        </div>
                    } */}


                    {(bookingData && bookingData.ItemisedExtras.length > 0) &&
                        <div className="test">
                            {bookingData.ItemisedExtras.map((extra, index) => (
                                <div key={index} className="tw-flex tw-justify-between">
                                    <span>{extra.Name}</span>
                                    <span>{bookingData.CurrencySymbol}{extra.Amount}</span>
                                </div>
                            ))}
                        </div>
                    }

                    {bookingData && bookingData.Discount > 0 &&
                        <div className="tw-flex tw-justify-between">
                            <span>Discount:</span>
                            <span>{bookingData.CurrencySymbol}{bookingData.Discount}</span>
                        </div>
                    }

                    {/* 
                    {bookingData && bookingData.BaseAmount > 0 &&
                        <div className="tw-flex tw-justify-between">
                            <span>Base Amount:</span>
                            <span>{bookingData.CurrencySymbol}{bookingData.BaseAmount}</span>
                        </div>
                    } */}




                    {bookingData && bookingData.HolidayExtraCost > 0 &&
                        <div className="tw-flex tw-justify-between">
                            <span>Extras:</span>
                            <span>{bookingData.CurrencySymbol}{bookingData.HolidayExtraCost}</span>
                        </div>
                    }

                    {/* {bookingData && bookingData.ExtraTotal > 0 &&
                        <div className="tw-flex tw-justify-between">
                            <span>Extra Total:</span>
                            <span>{bookingData.CurrencySymbol}{bookingData.ExtraTotal}</span>
                        </div>
                    } */}

                    {bookingData && bookingData.RentalRatesTax > 0 &&
                        <div className="tw-flex tw-justify-between">
                            <span>Rental Rates Tax:</span>
                            <span>{bookingData.CurrencySymbol}{bookingData.RentalRatesTax}</span>
                        </div>
                    }

                    {bookingData && bookingData.BookingFee > 0 &&
                        <div className="tw-flex tw-justify-between">
                            <span>Booking Fee:</span>
                            <span>{bookingData.CurrencySymbol}{bookingData.BookingFee}</span>
                        </div>
                    }

                    {bookingData && bookingData.BreakagesDeposit > 0 &&
                        <div className="tw-flex tw-justify-between">
                            <span>Breakages Deposit:</span>
                            <span>{bookingData.CurrencySymbol}{bookingData.BreakagesDeposit}</span>
                        </div>
                    }

                    {bookingData && bookingData.InsuranceTotal > 0 &&
                        <div className="tw-flex tw-justify-between">
                            <span>Insurance Total:</span>
                            <span>{bookingData.CurrencySymbol}{bookingData.InsuranceTotal}</span>
                        </div>
                    }

                    <div className="tw-border-t tw-border-gray-200 tw-h-full tw-mt-4 tw-mb-2"></div>

                    {bookingData && bookingData.RenterTotal > 0 &&
                        <div className="tw-flex tw-justify-center tw-font-bold tw-text-3xl">
                            <span className='tw-mr-4'>Total </span>
                            <span>{bookingData.CurrencySymbol}{bookingData.RenterTotal}</span>
                        </div>
                    }

                    {bookingData && bookingData.PayableToday > 0 &&
                        <div className="tw-flex tw-justify-center">
                            <span className='tw-mr-4'>Payable today </span>
                            <span className='tw-font-bold'>{bookingData.CurrencySymbol}{bookingData.PayableToday}</span>
                        </div>
                    }
                </div>
            }

            <div class="info tw-border tw-border-primary tw-p-2 tw-my-3">
                <h4 className='tw-mb-0'><FontAwesomeIcon icon={faCircleInfo} color="#67b26f" size="sm" className="tw-right-5 tw-top-5  " /> Book With Confidence</h4>
                <p className='tw-text-sm tw-m-0'>30% deposit upon booking, 70% balance payment 8 weeks prior to arrival date. Additional charges payable at the property</p>
            </div>


            <div className="tw-mt-2">
                {!error && !hideOnlineBooking && startDate && endDate &&
                    <a href={`?alttemplate=checkout_guest&t=acc&checkin=${startDate.format('DD/MM/YYYY')}&checkout=${endDate.format('DD/MM/YYYY')}&adults=${adults}&children=${children}&infants=${infants}&pets=${pets}&propertyId=${pageId}&propertylanguageId=0&sourceId=&propertyCurrencyId=`}
                        className="tw-bg-primary tw-text-white hover:tw-text-white hover:tw-bg-green-600 tw-no-underline tw-py-2 tw-px-4 tw-rounded tw-block tw-text-center tw-transition tw-duration-200">
                        Book
                    </a>
                } 


                {/* <a href={`?alttemplate=Enquiry&${startDate && endDate ? `checkin=${startDate.format('DD/MM/YYYY')}&checkout=${endDate.format('DD/MM/YYYY')}&` : ''}adults=${adults}&children=${children}&infants=${infants}&pets=${pets}&propertyId=${pageId}&propertylanguageId=0&sourceId=&propertyCurrencyId=`}
                    className="btn btn-primary !tw-text-white tw-no-underline !tw-py-2 tw-rounded !tw-block tw-text-center tw-mt-3 tw-transition tw-duration-200 tw-uppercase tw-font-montserrat">
                    Enquire
                </a> */}
            </div>
        </div>
    );
};

export default BookingForm;
