import { useEffect, useContext, useState, useRef } from 'react';
import PropertiesList from 'pages/ContentPage/Properties/PropertiesList.js';
import { SiteSettingsContext, CurrencyContext } from 'contexts/SiteSettingsProvider';
import WidgetWrapper from 'components/WidgetsList/WidgetWrapper/WidgetWrapper';
import SearchControlPanel from 'components/SearchResult/SearchControlPanel/SearchControlPanel';
import useWindowSize from "utils/useWindowSize";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import SearchResultMap from 'components/SearchResultMap/SearchResultMap';
import { SortContext } from 'contexts/SiteSettingsProvider';

const PropertyAutoSelectorWidget = ({ data, googleMapAPIKey }) => {
    const { sort: sortParam, order: orderParam } = useContext(SortContext);
    const isFirstUpdate = useRef(true);
    
    const [currency, setCurrency] = useContext(CurrencyContext);
    const { width } = useWindowSize();
    const [mapIsShwon, setMapVisability] = useState(true);
    const [sortDirection, setSortDirection] = useState('');
    const [displayMode, setDisplayMode] = useState("map");
    const [gridClass, setGridClass] = useState("tw-grid-cols-5");
    const [widgetPropertiesListGridClass, setPropertiesListGridClass] = useState("tw-grid sm:tw-grid-cols-1 md:tw-grid-cols-1 lg:tw-grid-cols-1 tw-gap-4 tw-gap-y-6");
    const [propertyList, setPropertyList] = useState(null);
    const [lastEvent, setLastEvent] = useState(null);

    useEffect(() => {
        if (width < window.lg) {
            setMapVisability(false);
        } else {
            setMapVisability(true);
        }
    }, [width]);
 
    useEffect(() => {
        if (data){
            setPropertyList(data.PropertiesList);
        }
        if (!data.PropertiesList) {
            setPropertyList([]);
        }
    }, [data]);

    useEffect(() => {
        if (isFirstUpdate.current) {
            isFirstUpdate.current = false;
            return;
        }

        if (data){
            changeSorting(null, data.Show, data.Page);
        }
    }, [currency]);

    function changeSorting(e, Show, Page) {
        let sortDirection;
        let sort;
        if (lastEvent){
            sortDirection = lastEvent.target.selectedOptions[0].getAttribute("sortdirection");
            sort = lastEvent.target.value;
        }

        if (e) {
            sortDirection = e.target.selectedOptions[0].getAttribute("sortdirection"); 
            sort = e.target.value;
        }
 
        setLastEvent(e);
 
        fetch(`${process.env.REACT_APP_BASE_URL}apis/renewresults?type=${data.WidgetType}&key=${data.WidgetId}&SortDirection=${sortDirection || orderParam}&Sort=${sort || sortParam}&Currency=${currency}&Show=${Show}&Page=${Page}`)
            .then(response => response.json())
            .then(data => {
                 
                setPropertyList(data.Items)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        
    }

    useEffect(() => {
        if (mapIsShwon === false) {
            setDisplayMode('fullWidth');
            setGridClass("tw-grid-cols-1");
            setPropertiesListGridClass("tw-grid sm:tw-grid-cols-1 md:tw-grid-cols-5 lg:tw-grid-cols-5 tw-gap-4 tw-gap-y-6")
        }
        if (mapIsShwon === true) {
            setDisplayMode('map');
            setGridClass("tw-grid-cols-5");
            setPropertiesListGridClass("tw-grid sm:tw-grid-cols-1 tw-grid-cols-1")
        }
    }, [mapIsShwon, displayMode]);

    function upMapState(val) {
        setMapVisability(!val);
    }

    const updateResults = (data) => {
        changeSorting(null, data.Show, data.Page);
        
    }
 
    return (
        <WidgetWrapper data={data}> 
            {data.Title && <h2>{data.Title}</h2>}
            {data.SubTitle && <h3>{data.SubTitle}</h3>}
            {data.Description && < div dangerouslySetInnerHTML={{ __html: data.Description }} /> }    
            <SearchControlPanel upMapState={upMapState} updateSorting={changeSorting}  />  

            <div className={`tw-grid ${gridClass} tw-gap-3`}>
                {propertyList && 
                    <PropertiesList 
                        propertyList={propertyList} 
                        widgetGrid={widgetPropertiesListGridClass} 
                        total={data.Total} 
                        sendParams={updateResults} 
                    />}
                
                {
                    (mapIsShwon && width > window.lg) ? <div className="lg:tw-columns-1 tw-col-span-2">
                        <div className="tw-sticky tw-top-0">
                            <div className="">
                                <Wrapper apiKey={googleMapAPIKey}  >
                                    <SearchResultMap mapData={propertyList} />
                                </Wrapper>
                            </div>
                        </div>
                    </div> : null
                }

                {
                    (mapIsShwon && width < window.lg) ?
                        <div className="tw-fixed tw-w-full tw-h-full tw-z-[9] tw-top-0 tw-left-0">
                            <div className="map">
                                <div className="">
                                    <Wrapper apiKey={googleMapAPIKey}  >
                                        <SearchResultMap mapData={propertyList} />
                                    </Wrapper>
                                </div>
                            </div>
                        </div> : null
                }
            </div>
            
        </WidgetWrapper >
    )
};

export default PropertyAutoSelectorWidget;