import React, { useState, useEffect, useRef } from 'react';
import styles from './Guests.module.css';
import DropDownPanel from '../../DropDownPanel/DropDownPanel';
import GuestsCounter from './GuestsCounter/GuestsCounter';

const Guests = ({ changeAdults, changChildren, changInfants, enableGuestFilter, clearField, settings }) => {
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [total, setTotal] = useState(1);

  const [maxGuest] = useState(20);
 

  function adultsHasChanged(val){
    setAdults(val);
    changeAdults(val);
  }

  function childrenHasChanged(val) {
    setChildren(val);
    changChildren(val);
  }

  function infantsHasChanged(val) {
    setInfants(val);
    changInfants(val);
  }

  useEffect(() => {
    setTotal(parseInt(adults) + parseInt(children) + parseInt(infants));
  }, [adults, children, infants])

  useEffect(() => {
    if (clearField >0) {
      setAdults(2);
      setChildren(0);
      setInfants(0);
      setTotal(2);
      changeAdults();
      changChildren();
      changInfants();
    }
  }, [clearField])

  const hasUpdatedOnce = useRef(false);

  useEffect(() => {
    if (!hasUpdatedOnce.current && !enableGuestFilter) {
      changeAdults(null);
      changChildren(null);
      changInfants(null);
      hasUpdatedOnce.current = true;
    }
  }, [enableGuestFilter, changeAdults, changChildren, changInfants]);

  return (
    <>
      {
       enableGuestFilter &&
        <div className={styles.Guests}>
            <DropDownPanel title={(settings?.Dictionaries['SearchForm.Guest'] || 'Guests') + " " + total}>
              <GuestsCounter name="Adults" min='1' max={maxGuest} initial={adults} upCount={adultsHasChanged} settings={settings}  />
              <GuestsCounter name="children" max={maxGuest} initial={children} upCount={childrenHasChanged} settings={settings} />
              <GuestsCounter name="Infants" max={maxGuest} initial={infants} upCount={infantsHasChanged} settings={settings}  />
          </DropDownPanel >
        </div>
      }
    </>
  );
};

Guests.propTypes = {};

Guests.defaultProps = {};

export default Guests;
