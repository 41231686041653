import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Lazy, EffectFade } from "swiper";
import WishButton from 'components/SearchResult/WishButton/WishButton';
import PropertyPrice from 'components/SearchResult/PropertyPrice/PropertyPrice';
import PropertyRating from 'components/SearchResult/PropertyRating/PropertyRating';
import SpecialOffer from 'components/SearchResult/SpecialOffer/SpecialOffer';
import 'react-loading-skeleton/dist/skeleton.css'
import 'swiper/css';
import 'swiper/css/lazy';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBed } from '@fortawesome/free-solid-svg-icons';
import { faPerson } from '@fortawesome/free-solid-svg-icons';
import { faBath } from '@fortawesome/free-solid-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Dropdown, DropdownButton } from 'react-bootstrap';

function truncateTextBasedOnWidth(text) {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1200 && screenWidth < 1400) {
        return truncateText(text, 120);
    }
    if (screenWidth >= 1400 && screenWidth < 1500) {
        return truncateText(text, 150);
    }
    if (screenWidth >= 1500 && screenWidth < 1700) {
        return truncateText(text, 200);
    }
    // For widths less than 1200 or more than 1700, return the full text
    return text;
}

function truncateText(text, length) {
    if (text.length > length) {
        return text.substring(0, length) + '...';
    }
    return text;
}

const PropertyItem = ({ item, index, linkParams = '', enableSlider, dictionary }) => {
    const [iconLimit, setIconLimit] = useState(0);
    const containerRef = useRef(null);
    const refProperties = useRef([]);

    useEffect(() => {
        function setDynamicIconLimit() {
            const windowWidth = window.innerWidth;

            let iconsToShow;

            if (windowWidth > 2000) {
                iconsToShow = 5;
            } else if (windowWidth > 1700 && windowWidth <= 2000) {
                iconsToShow = 3;
            } else if (windowWidth > 1600 && windowWidth <= 1700) {
                iconsToShow = 2;
            } else if (windowWidth > 1400 && windowWidth <= 1600) {
                iconsToShow = 1;
            } else if (windowWidth > 1000 && windowWidth <= 1400) {
                iconsToShow = 0;
            } else if (windowWidth > 800 && windowWidth <= 999) {
                iconsToShow = 2;
            } else if (windowWidth > 600 && windowWidth <= 799) {
                iconsToShow = 1;
            } else {
                iconsToShow = 2;
            }

            setIconLimit(iconsToShow);
        }
        // Initial set
        setDynamicIconLimit();

        // Listen for window resize events
        window.addEventListener('resize', setDynamicIconLimit);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('resize', setDynamicIconLimit);
        };
    }, []);

    if (!item) {
        return;
    }
 
 
    return (
        <>  {item &&
            <div key={item.Id} ref={element => (refProperties.current[item.Id] = element)} className='propertyItem overflow-hidden  tw-relative shadow xl:tw-flex tw-mb-5'>
                <div className='tw-relative xl:tw-max-w-[45%]' >
                    <PropertyRating ratingNumber={item.ReviewsRating} />
                    <WishButton size="2xl" heartColor="#f23b79" className='tw-absolute tw-z-1 tw-right-5 tw-top-5 tw-cursor-pointer' id={item.Id} />
                    <SpecialOffer item={item} />
                    {enableSlider ? <a href={item.MoreDetailsURL + linkParams} target="_blank" rel="noreferrer">
                        <Swiper
                            effect="fade"
                            spaceBetween={0}
                            slidesPerView={1}
                            loop={true}
                            pagination={true}
                            lazy={{ loadPrevNext: true, loadPrevNextAmount: 3 }}
                            navigation={true} modules={[EffectFade, Navigation, Pagination, Lazy]}
                        >
                            {
                                item.PropertyImagesUrls.map((image, index) => {
                                    if (index < 8) {
                                       
                                        return <SwiperSlide key={image + 't' + index} >
                                            <img src={'https://' + item.PropertyImagesUrls[0]}  data-src={'https://' + image} alt='img' className='swiper-lazy tw-w-full tw-h-full tw-object-fill' />
                                            <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                                        </SwiperSlide>
                                    }
                                }
                                )
                            }
                        </Swiper>
                    </a> :
                        <a href={item.MoreDetailsURL + linkParams} target="_blank" rel="noreferrer">
                            <img src={'https://' + item.PropertyImagesUrls[0]} alt='img' className='swiper-lazy tw-w-full tw-h-full tw-object-fill' />
                        </a>
                    }

                </div>
                 
                <div className="tw-flex tw-flex-col tw-w-full">

                    <div className="tw-p-5 tw-grow tw-pb-0 tw-pt-1">
                        <h2 className="tceft !tw-text-xl">{item.PropertyName}</h2>
                        <div className="tw-text-xl tw-text-primary tw-m-0 tw-font-light">
                            <FontAwesomeIcon icon={faLocationDot}  className="tw-pr-2" />
                            {item.Location}
                        </div>
                        <p className="tw-mt-3 IntroText tw-pb-0 tw-mb-0 tw-h-[70%]">{truncateTextBasedOnWidth(item.IntroText)}</p>
                    </div>

                    <div className="search-result-attribute-list tw-pl-5 tw-flex tw-flex-wrap tw-items-center tw-mb-3"  >
                        <div className="attribute-item tw-text-[1.5rem] tw-mr-5 tw-inline-flex tw-items-center tw-gap-1">
                            <span className="tw-py-2">{item.Sleeps} </span>
                            <FontAwesomeIcon icon={faPerson} color="#183153" className="tw-right-5 tw-top-5  " />
                        </div>
                        <div className="attribute-item tw-text-[1.5rem] tw-mr-5 tw-inline-flex tw-items-center tw-gap-1">
                            <span className="tw-py-2">{item.Bedrooms} </span>
                            <FontAwesomeIcon icon={faBed} color="#183153" className="tw-right-5 tw-top-5  " />
                        </div>
                        <div className="attribute-item tw-text-[1.5rem] tw-mr-5 tw-inline-flex tw-items-center tw-gap-1">
                            <span className="tw-py-2">{item.Bathrooms} </span>
                            <FontAwesomeIcon icon={faBath} color="#183153" className="tw-right-5 tw-top-5  " />
                        </div>
                        {
                            item.AllPropertyAttributes &&
                            item.AllPropertyAttributes
                                .filter(attr => attr.Icon !== null)
                                .map((attr, index) => (
                                    <div key={index} className="attribute-item tw-text-[1.5rem] tw-mr-5 tw-inline-flex tw-items-center tw-gap-1">
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip id={`tooltip-${index}`}>
                                                    {attr.AttrName}
                                                </Tooltip>
                                            }
                                        >
                                            <img src={attr.Icon} alt={attr.TypeName} className="tw-right-5 tw-top-5 tw-inline" width={'30'} height={'30'} />
                                        </OverlayTrigger>
                                    </div>
                                ))
                        }
                        
                        {/* {nonNullIcons.slice(0, iconLimit).map((attr, index) => (
                            <div key={index} className="attribute-item tw-text-[1.5rem] tw-mr-5 tw-inline-flex tw-items-center tw-gap-1">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id={`tooltip-${index}`}>{attr.AttrName}</Tooltip>}
                                >
                                    <img src={attr.Icon} alt={attr.TypeName} className="tw-inline" width={'30'} height={'30'} />
                                </OverlayTrigger>
                            </div>
                        ))}

                       
                        {nonNullIcons.length > iconLimit && (
                            <DropdownButton id="dropdown-basic-button" title="Show all" variant="light" size="sm" className='tw-mr-3 !tw-max-w-[50px]'>
                                {nonNullIcons.slice(iconLimit).map((attr, index) => (
                                    <Dropdown.Item key={index} className='tw-max-w-[100px]'>
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id={`tooltip-more-${index}`}>{attr.AttrName}</Tooltip>}
                                        >
                                            <img src={attr.Icon} alt={attr.TypeName} className="tw-inline" width={'30'} height={'30'} />
                                        </OverlayTrigger>
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        )} */}
                    </div>            

                    <div className="xl:tw-flex tw-bg-slate-300 tw-justify-between tw-items-center tw-w-full tw-item-center" ref={containerRef} >

                        <div className="tw-font-light  tw-text-[1.rem] md:tw-pl-5 tw-p-2 md:tw-p-0 tw-text-center md:tw-text-left "><PropertyPrice item={item} dictionary={dictionary} /></div>
                        <div className=" tw-flex">
                            <a href={item.MoreDetailsURL + linkParams} target="_blank" rel="noreferrer" className="tw-min-w-[120px] !tw-rounded-none tw-text-[#fff] btn-lg  btn btn-primary tw-text-[1.2rem] !tw-text-[#fff] tw-w-full  md:tw-w-full tw-w-inherit">View Villa  </a>
                        </div>
                    </div>
                </div>
            </div>
        }

        </>
    );
};

export default PropertyItem;